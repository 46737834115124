.root {
  padding: calc(var(--mantine-spacing-xl) * 0.8) 0;
}

.value {
  font-size: rem(24px);
  font-weight: 700;
  line-height: 1;
}

.diff {
  line-height: 1;
  display: flex;
  align-items: center;
}

.icon {
  color: light-dark(var(--mantine-color-gray-4), var(--mantine-color-dark-3));
}

.title {
  font-weight: 700;
  text-transform: uppercase;
}
