.wrapper {
  padding: 20px;
  width: 100%;
  max-width: 1200px;
  padding-bottom: 100px;
}

.title {
  margin-bottom: calc(var(--mantine-spacing-xl) * 1.5);
}

.subtitle {
  margin-top: var(--mantine-spacing-xl);
  margin-bottom: var(--mantine-spacing-lg);
  text-align: center;
}

.item {
  border-radius: var(--mantine-radius-md);
  margin-bottom: var(--mantine-spacing-lg);
  border: rem(1px) solid
    light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
}

.image {
  max-width: 900px;
  width: 100%;
}
