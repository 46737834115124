.partners {
  max-width: 1200px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  background-color: #f3f3f3;
  border-radius: 8px;
  margin-bottom: 20px;
}

.formStructure {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;
}

.submit {
  max-width: 200px;
  width: 100%;
}

@media only screen and (max-width: 560px) {
  .formStructure {
    grid-template-columns: 1fr;
  }
}
