.card {
  height: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background-size: cover;
  background-position: center;
  position: relative;
}

.title {
  font-weight: 900;
  line-height: 1.2;
  font-size: 22px;
  margin-top: var(--mantine-spacing-xs);
  cursor: default;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  gap: 2px;
}

.timeContainer {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: white;
  border-radius: var(--mantine-radius-xl);
  margin-bottom: 10px;
  width: auto;
}

.icon {
  cursor: pointer;
  user-select: none;
}

.overlay {
  color: #000;
  padding: 12px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 0.9em;
  gap: 3px;
}

.overlayDescription {
  color: white;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px;
  line-height: 1.2em;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.forbiddenSelect {
  user-select: none;
  margin: 0;
}

.addedBtns {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media screen and (max-width: 1275px) {
  .card {
    max-width: 500px;
  }
}
