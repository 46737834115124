.container {
  max-width: 1000px;
  width: 100%;
  height: 275px;
  display: grid;
  grid-template-columns: 400px 600px;
  border-radius: 8px;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  background-color: #f3f3f3;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;

  h2 {
    margin: 0 0 10px 0;
    line-height: 25px;
  }

  h3 {
    font-weight: normal;
    line-height: 1em;
    font-size: 0.9em;
    font-style: italic;
  }
}

.button {
  max-width: 150px;
  width: 100%;
}

.lastImage {
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}

@media only screen and (max-width: 990px) {
  .container {
    grid-template-columns: 1fr 1fr;
  }

  .slide {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
  }
}

@media only screen and (max-width: 600px) {
  .container {
    display: flex;
    flex-direction: column;
    height: fit-content;
  }

  .info {
    border-bottom-left-radius: 0;
    border-top-right-radius: 8px;
  }

  .slide {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 0;
  }
}
