.container {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  align-self: stretch;
  padding: 0 32px 80px 32px;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.title {
  color: #000;
  font-size: 32px;
  font-weight: 400;
  line-height: 1.2;
  display: flex;
  align-items: center;
  gap: 10px;

  b {
    font-family: monospace, sans-serif;
  }
}

.tripStatus {
  display: flex;
  align-items: center;
  gap: 10px;
}

.contentGrid {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;

  & div:first-child {
    flex: 1;
  }

  & div:last-child {
    flex: 1;
  }
}

.draft .main {
  display: flex;
  gap: 20px;
}

.draft .contentGrid {
  flex-direction: column;
  flex: 1;
}

@media (max-width: 900px) {
  .contentGrid {
    flex-direction: column;
  }
}

.vehicle,
.driver {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 16px;
  flex: 1 0 0;
}

.vehicleHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.status {
  font-size: 20px;
  font-weight: bold;
}

.information {
  display: flex;
  gap: 5px;
}

.subtitle {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  margin: 0;
}

@media (min-width: 1080px) {
  .header {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    height: 110px;
  }

  .contentGrid {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "trip-information driver" "trip-information vehicle";
  }
}

.priceContainer {
  display: flex;
  flex-direction: row;
  border: 2px solid #eee;
  border-radius: 5px;
  padding: 20px 0 20px 0;
  margin-top: 20px;
}

.priceTable {
  font-size: 1.3em;
  padding: 0 16px;

  td:first-child {
    font-weight: bold;
  }

  tr.priceTableDesc td {
    font-size: 0.7em;
    font-weight: normal;
  }
}

.editFormBlock {
  width: 100% !important;
  background: none !important;
}

.dataDisplay {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.scrollContainer {
  width: 100%;
}

@media screen and (min-width: 1275px) {
  .dataDisplay {
    flex: 1;
    align-items: flex-start;
  }
}
