.container {
  background-color: #f3f3f3;
  border-radius: 8px;
  padding: 16px 16px 16px 16px;

  h2:first-child {
    display: none;
  }

  h2 {
    font-size: 18px;
  }
}

.table td:first-child {
  width: 150px;
  text-align: right;
  padding-right: 10px;
  font-weight: bold;
}

.table td {
  vertical-align: top;
}
