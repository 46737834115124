.container {
  max-width: 1200px;
  margin: 0 auto;

  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.container a {
  text-decoration: none;
}

.headerOptions {
  display: none;
}

.option {
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  text-transform: capitalize;
  margin: 0;
}

.headerLink {
  color: white;
  font-weight: 700;
  cursor: pointer;
}

.icon {
  color: white;
  margin-left: 5px;
}

.logo {
  cursor: pointer;
}

.hamburgerIcon {
  display: block;
  pointer-events: all;
  cursor: pointer;
  color: white;
}

.xIcon {
  display: block;
  pointer-events: all;
  cursor: pointer;
  color: white;
}

.mobileContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background-color: #1d1d1d;
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 500ms ease,
    visibility 500ms ease;
}

.mobileContainer a {
  text-decoration: none;
}

.modalOpen {
  opacity: 1;
  visibility: visible;
}

.mobileHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 20px;
}

.mobileMenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  gap: 20px;
}

@media (min-width: 950px) {
  .headerOptions {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  .hamburgerIcon {
    display: none;
  }
}
