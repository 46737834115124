.container {
  max-width: 1200px;
  margin: 0 auto;

  height: 200px;
  padding: 20px 20px 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.container a {
  text-decoration: none;
}

.footerOptions {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.footerLinks {
  display: flex;
  gap: 20px;
}

.option {
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  text-transform: capitalize;
}

.rights {
  color: #696969;
}

@media (min-width: 660px) {
  .container {
    height: 130px;
    padding-top: 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .footerOptions {
    flex-direction: row;
  }
}
